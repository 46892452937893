import {
  OTP_INIT_ACTION,
  OTP_ACTION_SUCCESS,
  OTP_ACTION_ERROR,
  SUBMIT_OTP_INIT_ACTION,
  SUBMIT_OTP_ACTION_SUCCESS,
  SUBMIT_OTP_ACTION_ERROR,
  GOOGLE_LOGIN_INIT,
  GOOGLE_LOGIN_SUCCESS,
  GOOGLE_LOGIN_ERROR,
  EMAIL_LOGIN_INIT,
  EMAIL_LOGIN_SUCCESS,
  EMAIL_LOGIN_ERROR,
  FORGOT_PASSWORD,
  FORGOT_PASSWORD_SUCCESS,
  FORGOT_PASSWORD_ERROR,
  RESET_PASSWORD,
  RESET_PASSWORD_SUCCESS,
  RESET_PASSWORD_ERROR,
} from '../../actions';
import type { Middleware, Dispatch, MiddlewareAPI } from 'redux';
import { action as actionWrapper } from '../../Utils/helpers';
import {
  emailLoginApi,
  forgotPwdApi,
  gloginApi,
  resetPwdApi,
  sendOtp,
  submitOtp,
} from '../../Utils/apiRoutes';
import { helpers } from '@shopuptech/ui-core';

import type {
  EmailLoginAllActions,
  ForgotPwdAllActions,
  GoogleLoginAllActions,
  OTPAllActions,
  ResetPwdAllActions,
  SubmitOTPAllActions,
} from './login.types';
const { apiMiddleWare, handleErrorMiddleWare } = helpers;

const headers = new Headers();
headers.append('Content-Type', 'application/json');
const baseUrl = window.location.origin;

const loginMiddleware: Middleware = (store: MiddlewareAPI<any, any>) => (
  next: Dispatch,
) => (
  action:
    | OTPAllActions
    | SubmitOTPAllActions
    | GoogleLoginAllActions
    | EmailLoginAllActions
    | ForgotPwdAllActions
    | ResetPwdAllActions,
) => {
  const result = next(action);
  switch (action.type) {
    case OTP_INIT_ACTION:
      return fetch(baseUrl + sendOtp(), {
        method: 'POST',
        headers: headers,
        body: JSON.stringify({ phone: action.payload }),
      })
        .then((response) => {
          const contentType = response.headers.get('Content-Type');
          if (!contentType) {
            return { message: 'success' };
          }
          if (contentType?.includes('text/html')) {
            return response.text();
          } else {
            return response.json();
          }
        })
        .then((response) => {
          if (typeof response === 'string') {
            return response;
          } else {
            return response;
          }
        })
        .then(
          (response) => {
            store.dispatch(
              actionWrapper({
                type: OTP_ACTION_SUCCESS,
                payload: response,
              }),
            );
            return Promise.resolve(response);
          },
          (error) => {
            store.dispatch(
              actionWrapper({
                type: OTP_ACTION_ERROR,
                payload: { error: error.message },
              }),
            );
            return Promise.reject(error);
          },
        )
        .catch((error) => {
          store.dispatch(
            actionWrapper({
              type: OTP_ACTION_ERROR,
              payload: { error: error.toString() },
            }),
          );
          return Promise.reject(error);
        });
    case SUBMIT_OTP_INIT_ACTION:
      return fetch(baseUrl + submitOtp(), {
        method: 'POST',
        headers: headers,
        body: JSON.stringify({
          phone: action.payload.phone,
          code: action.payload.code,
        }),
        redirect: 'follow',
      })
        .then(apiMiddleWare)
        .then((response) => {
          if (typeof response === 'string') {
            return response;
          } else {
            return response;
          }
        })
        .then(
          (response) => {
            const { data: { user = {} } = {} } = response;
            store.dispatch(
              actionWrapper({
                type: SUBMIT_OTP_ACTION_SUCCESS,
                payload: user,
              }),
            );
            store.dispatch({
              type: 'main/CHECK_LOGIN_SUCCESS',
              payload: { ...user },
              meta: {
                moduleName: 'main',
              },
            });
            return Promise.resolve(response);
          },
          (error) => {
            store.dispatch(
              actionWrapper({
                type: SUBMIT_OTP_ACTION_ERROR,
                payload: { error: error.message },
              }),
            );
            return Promise.reject(error);
          },
        )
        .catch((error) => {
          store.dispatch(
            actionWrapper({
              type: SUBMIT_OTP_ACTION_ERROR,
              payload: { error: error.toString() },
            }),
          );
          return Promise.reject(error);
        });
    case GOOGLE_LOGIN_INIT:
      return fetch(baseUrl + gloginApi(), {
        method: 'POST',
        headers: headers,
        body: JSON.stringify({
          ...action.payload,
        }),
        redirect: 'follow',
      })
        .then(apiMiddleWare)
        .then(handleErrorMiddleWare)
        .then((response) => {
          if (typeof response === 'string') {
            return response;
          } else {
            return response;
          }
        })
        .then(
          (response) => {
            const { data: { user = {} } = {} } = response;
            store.dispatch(
              actionWrapper({
                type: GOOGLE_LOGIN_SUCCESS,
                payload: user,
              }),
            );
            store.dispatch({
              type: 'main/CHECK_LOGIN_SUCCESS',
              payload: { ...user },
              meta: {
                moduleName: 'main',
              },
            });
            return Promise.resolve(response);
          },
          (err) => {
            store.dispatch(
              actionWrapper({
                type: GOOGLE_LOGIN_ERROR,
                payload: err.error ? err.error.message : '',
              }),
            );
            return Promise.reject(err);
          },
        )
        .catch((error) => {
          store.dispatch(
            actionWrapper({
              type: GOOGLE_LOGIN_ERROR,
              payload: { error: error.toString() },
            }),
          );
          return Promise.reject(error);
        });
    case EMAIL_LOGIN_INIT:
      return fetch(baseUrl + emailLoginApi(), {
        method: 'POST',
        headers: headers,
        body: JSON.stringify({
          ...action.payload,
        }),
        redirect: 'follow',
      })
        .then(apiMiddleWare)
        .then(handleErrorMiddleWare)
        .then((response) => {
          if (typeof response === 'string') {
            return response;
          } else {
            return response;
          }
        })
        .then(
          (response) => {
            const { data: { user = {} } = {} } = response;
            store.dispatch(
              actionWrapper({
                type: EMAIL_LOGIN_SUCCESS,
                payload: user,
              }),
            );
            store.dispatch({
              type: 'main/CHECK_LOGIN_SUCCESS',
              payload: { ...user },
              meta: {
                moduleName: 'main',
              },
            });
            return Promise.resolve(response);
          },
          (err) => {
            store.dispatch(
              actionWrapper({
                type: EMAIL_LOGIN_ERROR,
                payload: err.error ? err.error.message : '',
              }),
            );
            return Promise.reject(err);
          },
        )
        .catch((error) => {
          store.dispatch(
            actionWrapper({
              type: EMAIL_LOGIN_ERROR,
              payload: { error: error.toString() },
            }),
          );
          return Promise.reject(error);
        });
    case FORGOT_PASSWORD:
      return fetch(baseUrl + forgotPwdApi(), {
        method: 'POST',
        headers: headers,
        body: JSON.stringify({
          ...action.payload,
        }),
        redirect: 'follow',
      })
        .then((response) => {
          const contentType = response.headers.get('Content-Type');
          if (!contentType) {
            return { message: 'success' };
          }
          if (contentType?.includes('application/json')) {
            return response.json();
          } else {
            return response.text();
          }
        })
        .then((response) => {
          if (typeof response === 'string') {
            return response;
          } else {
            return response;
          }
        })
        .then(
          (response) => {
            const { data: { user = {} } = {} } = response;
            store.dispatch(
              actionWrapper({
                type: FORGOT_PASSWORD_SUCCESS,
                payload: user,
              }),
            );
            return Promise.resolve(response);
          },
          (err) => {
            store.dispatch(
              actionWrapper({
                type: FORGOT_PASSWORD_ERROR,
                payload: err.error ? err.error.message : '',
              }),
            );
            return Promise.reject(err);
          },
        )
        .catch((error) => {
          store.dispatch(
            actionWrapper({
              type: FORGOT_PASSWORD_ERROR,
              payload: { error: error.toString() },
            }),
          );
          return Promise.reject(error);
        });
    case RESET_PASSWORD:
      return fetch(baseUrl + resetPwdApi(), {
        method: 'POST',
        headers: headers,
        body: JSON.stringify({
          ...action.payload,
        }),
        redirect: 'follow',
      })
        .then((response) => {
          const contentType = response.headers.get('Content-Type');
          if (!contentType) {
            return { message: 'success' };
          }
          if (contentType?.includes('application/json')) {
            return response.json();
          } else {
            return response.text();
          }
        })
        .then((response) => {
          if (response.error) {
            return Promise.reject({
              message: response.error.message,
              details: response.error.errors,
            });
          }

          if (typeof response === 'string') {
            return response;
          } else {
            return response;
          }
        })
        .then(
          (response) => {
            const { data: { user = {} } = {} } = response;
            store.dispatch(
              actionWrapper({
                type: RESET_PASSWORD_SUCCESS,
                payload: user,
              }),
            );
            return Promise.resolve(response);
          },
          (err) => {
            store.dispatch(
              actionWrapper({
                type: RESET_PASSWORD_ERROR,
                payload: err.error ? err.error.message : '',
              }),
            );
            return Promise.reject(err);
          },
        )
        .catch((error) => {
          store.dispatch(
            actionWrapper({
              type: RESET_PASSWORD_ERROR,
              payload: { error: error.toString() },
            }),
          );
          return Promise.reject(error);
        });
  }
  return result;
};
export default loginMiddleware;
